import ApplicationController from '../application_controller'

require('../../lib/polyfills/closest')

export default class extends ApplicationController {
  toggleVisibility (event) {
    var review     = this.element.closest('.review-preview, .review-full, .user')
    var shareSheet = review.querySelector('.share-sheet')

    event.preventDefault()

    shareSheet.classList.toggle('is-visible')
  }
}
