import ApplicationController from '../application_controller'

const INPUTS = 'input, textarea'

export default class extends ApplicationController {

  static targets = ['form', 'thanks', 'submit']

  static values = { url: String }

  // EVENTS:

  postReview (event) {
    this.submitTarget.disabled = true

    let formData = new FormData(this.formTarget)

    this.request = new XMLHttpRequest()
    this.request.responseType = 'json'

    this.request.onreadystatechange = function() {
      if (this.request.readyState != XMLHttpRequest.DONE) return

      switch (this.request.status) {
        case 200: case 201:
          this.reviewCreated(this.request.response)
          break

        case 422:
          this.reviewInvalid(this.request.response)
          break

        default:
          this.reviewFailed(this.request.response)
      }

      this.request = null
    }.bind(this)

    this.request.open('POST', this.urlValue)
    this.request.send(formData)

    event.preventDefault()
  }

  // REPONSES:

  reviewInvalid (response) {
    this.submitTarget.disabled = false

    if (response.author) {
      if (response.author.name)  response.author_name  = response.author.name
      if (response.author.email) response.author_email = response.author.email
    }

    for (let fieldName in response) {
      this.setInlineErrors(fieldName, response[fieldName])
    }

    // window.resizeParent()
  }

  reviewCreated (response) {
    this.formTarget.classList.remove('is-visible')
    this.thanksTarget.classList.add('is-visible')

    // window.resizeParent()
  }

  reviewFailed (response) {
    for (let fieldName of ['author_name', 'author_email', 'body']) {
      this.setInlineErrors(fieldName, null)
    }

    this.submitTargetdisabled = false

    // window.resizeParent()
  }

  // HELPERS:

  setInlineErrors (fieldName, errors = []) {
    let wrapper = this.formTarget.querySelector(`#review_${fieldName}_input`)

    if (!errors) {
      wrapper.classList.remove('error')
      return
    }

    errors = errors.join(', ')

    let existingErrors = wrapper.querySelector('.inline-errors')
    if (existingErrors) wrapper.removeChild(existingErrors)

    wrapper.classList.add('error')

    let newErrors = document.createElement('p')
    newErrors.appendChild(document.createTextNode(errors))

    let input = wrapper.querySelector(INPUTS)
    wrapper.insertBefore(newErrors, input.nextSibling)
  }

}
