import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = ['param', 'token']

  get csrfParam () { return this.getCookie('csrf_param') || 'authenticity_token' }
  get csrfToken () { return this.getCookie('csrf_token')                         }

  get inputs () {
    return this.element.querySelectorAll(`input[name="${this.csrfParam}"]`)
  }

  connect () {
    this.addCSRF = this.addCSRF.bind(this)

    this.addTargets()
    this.addCSRF()

    document.addEventListener('turbolinks:load', this.addCSRF)
  }

  disconnect () {
    document.removeEventListener('turbolinks:load', this.addCSRF)
  }

  addTargets () {
    if (!this.hasParamTarget) {
      let param = document.createElement('meta')
      param.setAttribute('name',                      'csrf-param')
      param.setAttribute(`data-${this.identifier}-target`, 'param')
      this.element.appendChild(param)
    }

    if (!this.hasTokenTarget) {
      let token = document.createElement('meta')
      token.setAttribute('name',                      'csrf-token')
      token.setAttribute(`data-${this.identifier}-target`, 'token')
      this.element.appendChild(token)
    }
  }

  addCSRF() {
    this.paramTarget['content'] = this.csrfParam
    this.tokenTarget['content'] = this.csrfToken

    this.inputs.forEach((input) => {
      input['value'] = this.csrfToken
    })
  }

  getCookie(a) {
    let match = document.cookie.match('(^|[^;]+)\\s*' + a + '\\s*=\\s*([^;]+)')

    if (match) {
      let encoded = match.pop()
      let decoded = decodeURIComponent(encoded)
      if (decoded.length) {
        return decoded
      }
    }

    return null
  }
}
