import _ from 'underscore'
import ApplicationController from './application_controller'
import { addListener, removeListener } from 'resize-detector'

export default class extends ApplicationController {

  get embedId () {
    return document.documentElement.dataset.embedId;
  }

  connect () {
    this.onResize             = this.onResize.bind(this)
    this.onResizeMessage      = this.onResizeMessage.bind(this)
    this.afterResize          = this.afterResize.bind(this)
    this.afterResizeTimeout   = null
    this.scrollPosition       = this.repositionStickyElements.bind(this)

    addListener(this.element, this.onResize)
    window.addEventListener('message', this.onResizeMessage, false)
    window.addEventListener('message', this.repositionStickyElements, false)

    _.defer(() => this.resizeParentFrame())
    _.defer(() => this.positionStickyElements())
  }

  disconnect () {
    removeListener(this.element, this.onResize)
    window.removeEventListener('message', this.onResizeMessage, false)
    window.removeEventListener('message', this.repositionStickyElements, false)
  }

  // EVENTS:

  onResize () {
    this.resizeParentFrame()
  }

  afterResize () {
    this.afterResizeTimeout = null
    this.element.classList.remove('is-resizing')
  }

  onResizeMessage (event) {
    if (event.data == `resize_${this.embedId}_widget`) {
      this.resizeParentFrame()

      if (this.afterResizeTimeout) window.clearTimeout(this.afterResizeTimeout)
      this.element.classList.add('is-resizing')
      this.afterResizeTimeout = window.setTimeout(this.afterResize, 500)
    }
  }

  // ACTIONS:

  resizeParentFrame () {
    if (window.parent && window.parent.postMessage) {
      let width  = this.fullWidth(this.element)
      let height = this.fullHeight(this.element)

      if (width > 0 && height > 0) {
        window.parent.postMessage(`resize_${this.embedId}_widget:${width}x${height}`, '*')
      }
    }
  }

  positionStickyElements () {
    var shoppingCart       = document.querySelector('.order-menu-shopping-cart')
    var shoppingCartHeight = shoppingCart.clientHeight

    var menuList       = document.querySelector('.menu-categories')
    var menuListHeight = shoppingCart.clientHeight + "px"

    shoppingCart.style.bottom = "calc(100% - "+shoppingCartHeight+")"
  }

  repositionStickyElements (event) {
    var uid = this.embedId

    var data    = event.data
    var message = data.split(':')

    var shoppingCart       = document.querySelector('.order-menu-shopping-cart')
    var shoppingCartHeight = shoppingCart.clientHeight

    var menuList       = document.querySelector('.menu-categories')
    var menuListHeight = menuList.clientHeight

    if (message[0] == `page_info`) {
      var positions      = message[1].split('x')
      var scrollPosition = parseInt(positions[0])
      var widgetOffset   = parseInt(positions[1])

      if (scrollPosition > widgetOffset) {
        shoppingCart.classList.add('is-sticky')
        shoppingCart.style.top = scrollPosition - widgetOffset + "px"
        shoppingCart.style.bottom = "calc(100vh - "+shoppingCartHeight+")"

        menuList.style.top = scrollPosition - widgetOffset + "px"
        menuList.style.bottom = "calc(100vh - "+menuListHeight+")"
      } else {
        shoppingCart.classList.remove('is-sticky')
        shoppingCart.style.top = 0
        shoppingCart.style.bottom = "calc(100vh - "+shoppingCartHeight+")"

        menuList.style.top = 0
        menuList.style.bottom = "calc(100vh - "+menuListHeight+")"
      }
    }
  }

  passCategoryPosition (event) {
    if (event.target) {
      var categoryId       = event.target.getAttribute('href').replace(/^(.+)?#/, '')
      var categoryElement  = document.getElementById(categoryId)
      var categoryPosition = categoryElement.offsetTop

      event.preventDefault()

      if (window.parent && window.parent.postMessage) {
        window.parent.postMessage(`category_position:${categoryPosition}`, '*')
      }
    }
  }

  // HELPERS:

  fullWidth (el) {
    let style = getComputedStyle(el)
    return parseFloat(style.marginLeft) + el.offsetWidth + parseFloat(style.marginRight)
  }

  fullHeight (el) {
    let style = getComputedStyle(el)
    return parseFloat(style.marginTop) + el.offsetHeight + parseFloat(style.marginBottom)
  }
}
