import _ from 'underscore'
import ApplicationController from './application_controller'

export default class extends ApplicationController {
  get iframe () {
    return this.element.getElementsByTagName('iframe')[0]
  }

  get contentWindow () {
    return this.iframe && this.iframe.contentWindow
  }

  connect () {
    _.defer(() => {
      this.resize()
    })
  }

  resize () {
    if (this.contentWindow && this.contentWindow.postMessage) {
      let message = {
        subject:   'viewportDimensions',
        embedId:   this.iframe.id,
        arguments: [window.innerWidth, window.innerHeight]
      }

      this.contentWindow.postMessage(message, '*')
    }
  }
}
