import md5 from '../../vendor/md5'
import _ from 'underscore'
import ApplicationController from '../application_controller'

export default class extends ApplicationController {

  static targets = ['result']

  get fingerprint () {
    return md5(
      [
        navigator.userAgent,
        [screen.height, screen.width, screen.colorDepth].join("x"),
        new Date().getTimezoneOffset(),
        !!window.sessionStorage,
        !!window.localStorage,
        this.pluginIdentifiers(navigator.plugins)
      ].join('###')
    )
  }

  connect() {
    _.defer(() => this.resultTarget.value = this.fingerprint)
  }

  pluginMimeTypes (plugin) {
    let result = []

    for (let mt of Array.from(plugin)) {
      result.push([mt.type, mt.suffixes].join('~'))
    }

    return result
  }

  pluginIdentifiers (plugins) {
    return Array.from(plugins).map(
      (p) => [p.name, p.description, this.pluginMimeTypes(p).join(',')].join('::')
    ).join(';')
  }
}
