import { currentUser } from '../../models/current_user'
import ApplicationController from '../application_controller'
import Rails from '@rails/ujs'

export default class extends ApplicationController {

  static targets = [ 'upvote', 'downvote' ]

  get upvoteButton()   { return this.upvoteTarget   }
  get downvoteButton() { return this.downvoteTarget }

  // Is voting enabled for the current user?
  get enabled () {
    return this.data.get('enabled') != 'false'
  }
  set enabled (value) {
    this.data.set('enabled', value)

    if (this.enabled) {
      this.element.classList.add('enabled')
    } else {
      this.element.classList.remove('enabled')
    }
  }

  // Return all votes made by current user
  get votes () {
    if (currentUser && currentUser.user) {
      return currentUser.user.votes || []
    } else {
      return []
    }
  }

  // The total number of upvotes
  get upvotes () {
    return parseInt(this.data.get('upvotes'))
  }
  set upvotes (value) {
    this.data.set('upvotes', value)

    if (this.hasUpvoteTarget) this.upvoteButton.innerText = value
  }

  // Has the current user upvoted or not?
  get upvoted () {
    return this.data.get('upvoted') == 'true'
  }
  set upvoted (value) {
    this.data.set('upvoted', value)

    if (value) {
      this.upvoteButton.classList.add('active')
    } else {
      this.upvoteButton.classList.remove('active')
    }
  }

  // The total number of downvotes
  get downvotes () {
    return parseInt(this.data.get('downvotes'))
  }
  set downvotes (value) {
    this.data.set('downvotes', value)

    if (this.hasDownvoteTarget) this.downvoteButton.innerText = value
  }

  // Has the current user downvoted or not?
  get downvoted () {
    return this.data.get('downvoted') == 'true'
  }
  set downvoted (value) {
    this.data.set('downvoted', value)

    if (value) {
      this.downvoteButton.classList.add('active')
    } else {
      this.downvoteButton.classList.remove('active')
    }
  }

  // the currently-active vote of the current user:
  get currentVote () {
    return {
      enabled:   this.enabled,
      upvoted:   this.upvoted,
      downvoted: this.downvoted
    }
  }
  set currentVote (value) {
    if (value) {
      this.enabled   = value.enabled
      this.upvoted   = value.upvoted
      this.downvoted = value.downvoted
    }
  }

  initialize() {
    this.request     = null
    this.currentVote = {
      enabled:   true,
      upvoted:   false,
      downvoted: false
    }
  }

  updateUserVote () {
    let vote = this.votes[this.id]
    if (vote) {
      this.currentVote = {
        enabled:   !vote.disabled,
        upvoted:   vote.positive,
        downvoted: !vote.positive
      }
    } else {
      this.currentVote = {
        enabled:   true,
        upvoted:   false,
        downvoted: false
      }
    }
  }

  connect () {
    this.id = this.data.get('id')

    if (currentUser) {
      currentUser.subscribe(this, this.updateUserVote.bind(this))
    }
  }

  disconnect () {
    if (currentUser) {
      currentUser.unsubscribe(this)
    }
  }

  vote(options) {
    if (this.request) this.request.abort()

    let oldVote = this.currentVote
    this.currentVote = options

    let request = new XMLHttpRequest()
    request.onreadystatechange = function() {
      if (request.readyState != XMLHttpRequest.DONE) return

      switch (request.status) {
        case 200: case 201:
          eval(request.responseText)
          break

        default:
          if (this.upvoted) {
            this.upvotes--
          } else if (this.downvoted) {
            this.downvotes--
          } else {
            this.upvotes++
          }

          this.currentVote = oldVote
      }

      this.request = null
    }.bind(this)

    let url        = this.data.get('url')
    let method     = ((options.upvoted || options.downvoted) ? 'POST' : 'DELETE')
    let parameters = {
      _method: method,
      vote:    { positive: options.upvoted }
    }

    request.open(method, url)
    request.setRequestHeader('Content-Type', 'application/json')
    request.setRequestHeader('X-CSRF-Token', Rails.csrfToken())
    request.setRequestHeader('Accept', 'text/javascript, application/javascript, application/ecmascript, application/x-ecmascript')
    request.send(JSON.stringify(parameters))

    this.request = request
  }

  unvote() {
    if (!this.enabled) return

    if (this.upvoted)   this.upvotes--
    if (this.downvoted) this.downvotes--

    this.vote({ enabled: true, upvoted: false, downvoted: false })
  }

  upvote(event) {
    event.preventDefault()

    if (!this.enabled) return

    if (!this.upvoted) {
      this.upvotes++
      if (this.downvoted) this.downvotes--

      this.vote({ enabled: true, upvoted: true, downvoted: false })
    } else {
      this.unvote()
    }
  }

  downvote(event) {
    event.preventDefault()

    if (!this.enabled) return

    if (!this.downvoted) {
      this.downvotes++
      if (this.upvoted) this.upvotes--

      this.vote({ enabled: true, upvoted: false, downvoted: true })
    } else {
      this.unvote()
    }
  }
}
