import Rails from '@rails/ujs'
import _ from 'underscore'

class CurrentUser {
  constructor() {
    this._done = []
    this._fail = []

    this.user = {}
    this.subscriptions = new Map();
    this.request = null
    this.fetch()
  }

  updateSubscriptions () {
    for (let [identifier, callback] of this.subscriptions) {
      if (_.isFunction(callback)) callback()
    }
  }

  subscribe (identifier, callback) {
    this.subscriptions.set(identifier, callback)

    if (this.request && this.request.readyState == 4) {
      this.request.addEventListener('load', callback)
    } else if (!this.request) {
      this.refresh()
    }
  }

  unsubscribe (identifier) {
    if (this.subscriptions.has(identifier)) {
      this.subscriptions.delete(identifier)
    }
  }

  load (userData) {
    this.user = _.extend(this.user, userData)

    for (let callback of this._done) callback()
    this.updateSubscriptions()

    this._done = []
    this._fail = []
  }

  unload () {
    this.user = {}

    for (let callback of this._fail) callback()
    this.updateSubscriptions()

    this._done = []
    this._fail = []
  }

  update () {
    if (!this.request || this.request.readyState !== XMLHttpRequest.DONE) return

    switch (this.request.status) {
      case 200: case 201:
        let data = JSON.parse(this.request.responseText)
        this.load(data.currentUser)
        break

      default:
        this.unload()
    }
  }

  refresh() {
    if (this.request) {
      this.request.abort()
      this.request = null
    }

    let request = new XMLHttpRequest()

    let url = '/account.json'
    if (this.user.id) {
      url = `${url}?${(new Date()).getTime()}`
    }

    request.onreadystatechange = this.update.bind(this)

    request.open('GET', url)
    request.setRequestHeader('Content-Type', 'application/json')
    request.setRequestHeader('X-CSRF-Token', Rails.csrfToken())
    request.setRequestHeader('Accept', 'application/json, text/javascript')

    this.request = request

    request.send()

    return this
  }

  done (callback) {
    if (null == this.request) this.refresh()

    this._done.push(callback)
    if (this.request.readyState == XMLHttpRequest.DONE) this.update()

    return this
  }

  fail (callback) {
    if (null == this.request) this.refresh()

    this._fail.push(callback)
    if (this.request.readyState == XMLHttpRequest.DONE) this.update()

    return this
  }

  fetch () {
    if (null == this.request) this.refresh()
    return this
  }
}

export const currentUser = new CurrentUser()
