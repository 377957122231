import { Controller } from '@hotwired/stimulus'
import _ from 'underscore'

export default class extends Controller {
  generateUniqueId () {
    return [
      new Date().getTime(),
      Math.random() * 1000000
    ].map((number) => number.toString(36)).join('+')
  }

  findParentController (identifier) {
    return this.controllerFor(this.element.closest(`[data-controller*="${identifier}"]`), identifier)
  }

  findChildController (identifier) {
    let element = this.element.querySelector(`[data-controller*="${identifier}"]`)
    if (element) {
      return this.controllerFor(element, identifier)
    }
  }

  findChildControllers (identifier) {
    return _.compact(
      [
        ...this.element.querySelectorAll(`[data-controller*="${identifier}"]`)
      ].map(
        (element) => this.controllerFor(element, identifier)
      )
    )
  }

  findControllers (identifier) {
    return _.compact(
      [
        ...document.querySelectorAll(`[data-controller*="${identifier}"]`)
      ].map(
        (element) => this.controllerFor(element, identifier)
      )
    )
  }

  findController (identifier) {
    return this.findControllers(identifier)[0]
  }

  controllerFor(element, identifier) {
    return this.application.getControllerForElementAndIdentifier(element, identifier)
  }
}
